export * from '@oracle-cx-commerce/endpoints';
export const _getAddress = () => import('./TRX/getAddress');
export const _getHerbalife = () => import('./TRX/getHerbalife');

export const getAudienceMembership = () => import('./PortInfo/get-audience-membership');
export const _getIpAddress = () => import('./PortInfo/get-ip-address');
export const _getProductExternalPrices = () => import('./PortInfo/get-product-external-prices');
export const _getOrderNFEXML = () => import('./PortInfo/get-order-nfe-xml');
export const _getNFEHTMLFromXML = () => import('./PortInfo/get-nfe-html-from-xml');
export const _getProductsExternalPrices = () => import('./PortInfo/get-products-external-prices');
export const _getDefaultOrganizationMember = () => import('./PortInfo/get-default-organization-member');
export const _getUserLocations = () => import('./PortInfo/get-user-locations');
export const _getProfileInvoices = () => import('./PortInfo/get-profile-invoices');
export const _getProfileCharges = () => import('./PortInfo/get-profile-charges');
export const _getShopperProfileByCPF = () => import('./PortInfo/get-shopper-profile-by-cpf');
export const _getContactCreditLimit = () => import('./PortInfo/get-contact-credit-limit');
export const _getContactInvoiceInstallments = () => import('./PortInfo/get-contact-invoice-installments');
export const _getRepresentedOrders = () => import('./PortInfo/get-represented-orders');

export const _getOrganizationByCNPJ = () => import('./PortInfo/get-organization-by-cnpj');
